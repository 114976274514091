import * as React from 'react';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { Entities } from '@inwink/entities/entities';
import { templatedString } from '@inwink/utils/methods/templatedString';
import { getBlocTemplate } from '@@services/itemshelpers';
import {
    getHomeExhibitors,
    getThemeExhibitors,
    getExhibitorsByIds,
    getAffiliated,
    setCurrentExhibitorsContext,
    getRecommendationPredicate,
    getJourneyExhibitors
} from '../data.exhibitors';
import type { IDynamicBlocProps } from '@@components/dynamicpage/common';
import { TeaserItems } from '@@components/dynamicpage/teaseritems';
import { SortAndFilterItems } from '@@components/dynamicpage/teaseritems.props';
import type { States } from '@@services/services';
import { getUrlSlug } from 'helpers';

import './bloc.teaserexhibitors.less';

export interface IBlocExhibitorsState {
    exhibitors: Entities.IExhibitor[];
    itemtemplate: VisualTheme.IItemTemplate;
    loading: boolean;
}

export function filterExhibitors(eventdata: States.IEventDataStore, userState: States.IAppUserState,
    i18n: States.i18nState, page: States.ICurrentPageState, blocProperties) {
    const data = eventdata;
    let exhibitors: any;

    if (blocProperties.showFavorites) {
        const user = userState && userState.currentUser && userState.currentUser.data;
        if (user) {
            const ids = user.favoriteExhibitors.data.filter((s) => s.status === "Active")
                .map((s) => { return { id: s.exhibitorId }; });
            exhibitors = getExhibitorsByIds(ids, data);
        }
    } else if (blocProperties.whitelist && blocProperties.whitelist.length) {
        exhibitors = getExhibitorsByIds(blocProperties.whitelist.map((s) => { return { id: s }; }), data);
    } else if (page.context && page.context.entityKind && page.context.entityKind === "EventTheme" && page.context.entity) {
        exhibitors = getThemeExhibitors(page.context.entity.id, data);
    } else if (page.context && page.context.entityKind && page.context.entityKind === "Journey" && page.context.entity) {
        exhibitors = getJourneyExhibitors(page.context.entity.id, data);
    } else if (page.context && page.context.entityKind && page.context.entityKind === "Session" && page.context.entity) {
        if (page.context.entity.exhibitors) {
            exhibitors = getExhibitorsByIds(page.context.entity.exhibitors, data);
        } else {
            exhibitors = [];
        }
    } else if (page.context && page.context.entityKind && page.context.entityKind === "Exhibitor" && page.context.entity) {
        exhibitors = getAffiliated(page.context.entity,
            blocProperties?.grabParent,
            blocProperties?.grabChildren,
            blocProperties?.grabBrothers, data);
    } else if (page.context && page.context.entityKind && page.context.entityKind === "ExhibitorOffering"
        && page.context.entity && page.context.entity.exhibitorId) {
        exhibitors = getExhibitorsByIds([{ id: page.context.entity.exhibitorId }], data);
    } else {
        exhibitors = getHomeExhibitors(data);
    }

    if (blocProperties?.showRecommandations && userState?.currentUser) {
        const recommandationsPredicate = getRecommendationPredicate(userState, null, true);
        const recommendedItems = exhibitors.filter((e) => recommandationsPredicate(e));
        if (recommendedItems?.length) {
            exhibitors = recommendedItems;
        }
    }

    return SortAndFilterItems(userState, i18n, exhibitors, blocProperties, true);
}

export function blocExhibitorsData(page: States.ICurrentPageState, bdata, bloc: VisualTheme.IBlocContentTemplateBase,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: States.IAppState) {
    const blocdata = bdata;
    const data = state.event.data;
    if (data) {
        blocdata.itemtemplate = getBlocTemplate(data, bloc);
        blocdata.exhibitors = filterExhibitors(data, state.user, state.i18n, page, bloc.properties);
    }
}

const defaultExhibitorLayout = {
    default: "col3",
    L: "col3",
    M: "col3",
    XS: "col2",
    XXS: "col1",
    XXXS: "col1",
    XXXXS: "col1"
};

export class BlocExhibitors extends React.PureComponent<IDynamicBlocProps<IBlocExhibitorsState>, any> {
    constructor(props: IDynamicBlocProps<IBlocExhibitorsState>) {
        super(props);
        let itemtemplate;
        if (props.blocState && props.blocState.itemtemplate) {
            itemtemplate = props.blocState.itemtemplate;
        } else if (props.template.properties && props.template.properties.template) {
            itemtemplate = props.template.properties.template;
        }

        this.state = {
            itemtemplate,
            entitytemplate: this.props.event?.data?.fieldtemplates
                && this.props.event.data.fieldtemplates.data.find((f) => f.entityName.toLowerCase() === "exhibitor")
        };
    }

    componentDidUpdate(prevProps: IDynamicBlocProps<IBlocExhibitorsState>) {
        if (prevProps.blocState !== this.props.blocState) {
            this.setState({ itemtemplate: this.props.blocState.itemtemplate });
        }
        if (this.props.user?.checked && !prevProps.user?.checked) {
            const newdata = {} as any;
            blocExhibitorsData(this.props.page, newdata, this.props.template, {
                event: this.props.event,
                user: this.props.user,
                i18n: this.props.i18n
            } as any);
            this.props.update(newdata);
        }
    }

    getExhibitorUrl = (exhibitor: Entities.IExhibitor) => {
        if (this.props.template.properties && this.props.template.properties.targetPage) {
            return this.props.urlservice.pageUrl(templatedString(this.props.template.properties.targetPage,
                { ...this.props.datacontext, entity: exhibitor }));
        }

        return this.props.urlservice.pageUrl(`partner/${exhibitor.id}/${getUrlSlug(exhibitor.name)}`);
    };

    entityClicked = (entity: Entities.IExhibitor) => {
        setCurrentExhibitorsContext(this.props.event, this.props.blocState.exhibitors.map((e) => e.id), entity.id);
    };

    render() {
        if (!this.props.blocState || !this.state.itemtemplate) {
            return <div className="empty" />;
        }

        let link;
        let className;
        let itemClick;
        if (this.props.template && this.props.template.properties && !this.props.template.properties.disableNavigation) {
            link = this.getExhibitorUrl;
            itemClick = this.entityClicked;
        }

        if (this.state && this.state.itemtemplate && this.state.itemtemplate.customCSS === undefined) {
            className = "defaultexhibitoritem";
        }

        return <TeaserItems
            {...this.props}
            className={className}
            entitySchema={this.state.entitytemplate}
            defaultItemsAlign="left"
            defaultItemsLayout={defaultExhibitorLayout}
            templateProperties={this.props.template?.properties?.templateProperties}
            allowEmpty={this.props.template?.properties?.allowEmpty}
            emptyMessage={this.props.template?.properties?.emptyMessage}
            minCarouselItems={this.props.template?.properties?.minCarouselItems}
            items={this.props.blocState ? this.props.blocState.exhibitors : null}
            itemClassName="exhibitor-item"
            linkto={link}
            onItemClick={itemClick}
            itemtemplate={this.state.itemtemplate}
        />;
    }
}
