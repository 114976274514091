import { registerBloc } from '../../../components/dynamicpage/blocscatalog';
import { registerExhibitorTemplateParts } from './exhibitors/registerItemTemplateParts';
import { BlocExhibitors, blocExhibitorsData } from "./exhibitors/blocs/bloc.teaserexhibitors";

registerExhibitorTemplateParts();

registerBloc("teaserexhibitors", {
    component: BlocExhibitors,
    datainit: blocExhibitorsData
});
